import { Button, Card, Checkbox, Col, Form, Input, message, Modal, Popconfirm, Popover, Radio, Row, Table, Typography } from "antd";
import React, { useState } from "react";
import { Link } from "react-router-dom/cjs/react-router-dom.min";
import ProjectExpencesController from "./ProjectExpencesController";
import MyAvatar from "../../components/Avatar/MyAvatar";
import { removeTitle } from "../../util/nameFilter";
import { getRoles } from "../../util/hasPermission";
import moment from "moment";
import "../Complaints/ComplaintsForm.css"
import { DeleteOutlined, EditOutlined, EyeOutlined, FileTextOutlined, PlusOutlined, QuestionCircleOutlined } from "@ant-design/icons";
import { hideAuthLoader, showAuthLoader } from "../../appRedux/actions";
import { useDispatch } from "react-redux";
import Service from "../../service";

const Projectexpences = () => {
  const {
    popOver,
    setPopOver,
    handleVisibleChange,
    handleSearchTechnology,
    searchTechnology,
    projectexpencesList,
    filteredTechnologyList,
    technology,
    setTechnology,
    handleFilters,
    filteredManagerList,
    handleSearchManager,
    searchManager,
    manager,
    setManager,
    accontManager,
    setAccountManager,
    searchProject,
    handleSearchProjects,
    filteredProjectsList,
    selectedProject,
    setSelectedProject,
    handleSearchAccountManager,
    searchAccountManager,
    filteredAccManagerList,
    handleTableChange,
    pagination,
    need_to_bill_customer,
    handleReviewTypeFilter,
    getprojectexpencesList,
    isModalOpenTopic,
    setIsModalOpenTopic,
    getReviewById,
    feedBackDetails,
    setFeedBackDetails,
    deleteProjectExpences
  } = ProjectExpencesController();

  let userData = JSON.parse(localStorage.getItem("user_data"))
  const userHasAccess = getRoles(["Super Admin", "PC", "TL", "Admin", "AM", "User"])

  const userHasAccessClient = userData._id =="67c81990506e8203272e6267"
  // userData._id == ' 67c7db5029ddb37be4309883'

  const [userRole, setuserRole] = useState(JSON.parse(localStorage.getItem('user_data')))
  const [formDetail] = Form.useForm();
  const [viewData, setViewData] = useState({})
  const { Text } = Typography;

  const dispatch = useDispatch();

  const showTotal = (total) => `Total Records Count is ${total}`;

  const columns = [
    {
      title: "Project",
      render: (text) => (text?.project?.title ? text?.project?.title : "-"),
      width: 400,
      ellipsis: true,     },
    {
      title: "Amount",
      render: (text) =>
        text?.cost_in_usd ?<span style={{display:'flex',justifyContent:'start',gap:'5px'}}><span>$</span><span>{text?.cost_in_usd }</span></span>  : "-",
    },
    {
      title: "Need to Bill Customer",
      render: (text) => (text?.need_to_bill_customer == true ? "YES" : "NO"),

    },
    {
      title: "Created By",
      render: (text) =>
        text?.createdBy?.full_name ? text?.createdBy?.full_name : "-",
    },
  
    {
      title: "Date",

      render: (text) => {
        console.log(text, 'text');
        const createdDate = moment(text.createdAt).format("DD MMM YYYY");
        return <span>{createdDate ? createdDate : "-"}</span>;
      },
    },

    {
      title: "Status",

      render: (text) => {
        // console.log(text, 'text');
        // const createdDate = moment(text.createdAt).format("DD MMM YYYY");
        return <span>{text.status}</span>;
      },
    },
    


  
    userHasAccess
    ? 
        {
          title: "Actions",
          render: (text, record) => (
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "start",
                alignItems: "center",
                gap: "20px",
              }}
            >
              <EyeOutlined
                onClick={() => {
                  getReviewForEdit(text?._id);
                  if (feedBackDetails) {
                    setIsModalOpenTopic(true);
                  }
                }}
                style={{ cursor: "pointer" }}
              />
              <Link to={"edit/projectexpenseform/" + text._id}>
                <EditOutlined style={{ color: "green" }} />
              </Link>
              <Popconfirm
                icon={<QuestionCircleOutlined style={{ color: "red" }} />}
                title="Are you sure to delete this Expense?"
                onConfirm={() => deleteProjectExpences(text._id)}
                okText="Yes"
                cancelText="No"
              >
                <DeleteOutlined style={{ color: "red" }} />
              </Popconfirm>
            </div>
          ),
        }
      
    : userHasAccessClient
    ? 
        {
          title: "Actions",
          render: (text, record) => (
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "center",
                alignItems: "center",
                gap: "20px",
              }}
            >
              <EyeOutlined
                onClick={() => {
                  getReviewForEdit(text?._id);
                  if (feedBackDetails) {
                    setIsModalOpenTopic(true);
                  }
                }}
                style={{ cursor: "pointer" }}
              />
              <Link to={"edit/projectexpenseform/" + text._id}>
                <EditOutlined style={{ color: "green" }} />
              </Link>
            </div>
          ),
        }
      
    : {}
  

    // ...(userHasAccess
    //   ? [
    //     {
    //       title: "Actions",
    //       render: (text, record) => (

    //         <div
    //           style={{
    //             display: "flex",
    //             flexDirection: "row",
    //             justifyContent: "start",
    //             alignItems: "center",
    //             gap: "20px",
    //           }}
    //         >
    //           <EyeOutlined
    //             onClick={() => {
    //               getReviewForEdit(text?._id)
    //               if (feedBackDetails) {
    //                 setIsModalOpenTopic(true);
    //               }
    //             }}
    //             style={{ cursor: "pointer" }}
    //           />
    //           <Link to={"edit/projectexpenseform/" + text._id}>
    //             <EditOutlined style={{ color: "green" }} />
    //           </Link>
    //           <Popconfirm
    //             icon={
    //               <QuestionCircleOutlined
    //                 style={{
    //                   color: "red",
    //                 }}
    //               />
    //             }
    //             title="Are you sure to delete this Expense ?"
    //             onConfirm={() => {
    //               deleteProjectExpences(text._id)
    //             }}

    //             okText="Yes"
    //             cancelText="No"
    //           >
    //             <DeleteOutlined style={{ color: "red" }} />
    //           </Popconfirm>
    //         </div>
    //       ),
    //     },
    //   ]
    //   : []),



    // ...(userHasAccessClient ? [
    //   {
    //     title: "Actions",
    //     render: (text, record) => (

    //       <div
    //         style={{
    //           display: "flex",
    //           flexDirection: "row",
    //           justifyContent: "center",
    //           alignItems: "center",
    //           gap: "20px",
    //         }}
    //       >
    //         <EyeOutlined
    //           onClick={() => {
    //             getReviewForEdit(text?._id)
    //             if (feedBackDetails) {
    //               setIsModalOpenTopic(true);
    //             }
    //           }}
    //           style={{ cursor: "pointer" }}
    //         />
    //         <Link to={"edit/projectexpenseform/" + text._id}>
    //           <EditOutlined style={{ color: "green" }} />
    //         </Link>

    //       </div>
    //     ),
    //   },
    // ]
    //   : [])
  ];
  const getReviewForEdit = async (review_id) => {
    //   setReviewId(review_id);
    try {
      dispatch(showAuthLoader());
      const reqBody = {
        _id: review_id,
      };
      const response = await Service.makeAPICall({
        methodName: Service.postMethod,
        api_url: Service.getprojectexpanses,
        body: reqBody,
      });

      dispatch(hideAuthLoader());
      if (response?.data && response?.data?.data) {
        //   setSelectedFeedbackType(response.data.data?.feedback_type)

        setViewData(response.data.data)
        formDetail.setFieldsValue({
          // project: response.data.data?.project?._id,
          purchase_request_details: response.data.data?.purchase_request_details?.replace(/<br\s*\/?>/g, "\n"),
          details:response.data.data?.details
          // cost_in_usd:response.data.data?.cost_in_usd,
          // need_to_bill_customer: response.data.data?.need_to_bill_customer,
          // status:response.data.data?.status
        });
      }
    } catch (error) {
      console.log(error);
    }
  };
  const exportCSV = async () => {
    try {
      const reqBody = {
        exportFileType: "csv",
        isExport: true,
      };

      const response = await Service.makeAPICall({
        methodName: Service.postMethod,
        api_url: Service.exportProjectExpenses,
        body: reqBody,
      });
      console.log(response.data, "exportcsv");
      const exportButton = document.getElementById("exportButton");
      if (response?.data?.data) {
        let base64 = response.data.data;
        const linkSource = "data:text/csv;base64," + base64;
        const downloadLink = document.createElement("a");
        const fileName = "Project Expense.csv";
        downloadLink.href = linkSource;
        downloadLink.download = fileName;
        downloadLink.style.display = "none";
        downloadLink.click();
        downloadLink.remove();
      } else {
        message.error(response.data.message);
        exportButton.disabled = true;
      }
    } catch (error) {
      console.log(error);
    }
  };
  return (
    <>
      <div className="ant-project-task  all-project-main-wrapper positive-feedback-review">
        <Card>
          <div className="profile-sub-head">
            <div className="head-box-inner">
              <div className="heading-main">
                <h2>Project Expense </h2>
              </div>
              {getRoles(["Admin", "PC", "Super Admin", "AM", "TL"]) && (
                <Link to="/add/projectexpenseform">
                  <Button type="primary" className="square-primary-btn">Add Project Expense </Button>
                </Link>
              )}
            </div>

            <div className="status-content">

              {!userHasAccessClient &&
                <div style={{ cursor: "pointer" }}>

                  <h6>Project:</h6>
                  <Popover
                    trigger="click"
                    placement="bottomRight"
                    visible={popOver.project}
                    onVisibleChange={() => handleVisibleChange("project", true)}
                    content={
                      <div className="assignees-popover assign-global-height">
                        <ul style={{ listStyle: "none" }}>
                          <li>
                            <Checkbox
                              checked={selectedProject.length === 0}
                              onChange={() =>
                                handleFilters(
                                  "",
                                  selectedProject,
                                  setSelectedProject
                                )
                              }
                            >
                              {" "}
                              All
                            </Checkbox>
                          </li>
                        </ul>
                        <div className="search-filter">
                          <Input
                            placeholder="Search"
                            value={searchProject}
                            onChange={handleSearchProjects}
                          />
                        </div>
                        <div>
                          <ul className="assigness-data" style={{ listStyle: "none" }}>
                            {filteredProjectsList.map((item, index) => (
                              <>
                                {/* <Option
                              key={index}
                              value={item._id}
                              style={{ textTransform: "capitalize" }}
                            >
                              {item.title}
                            </Option> */}
                                <li key={item._id}>
                                  <Checkbox
                                    onChange={() => {
                                      handleFilters(
                                        item,
                                        selectedProject,
                                        setSelectedProject
                                      );
                                    }}
                                    checked={selectedProject.includes(item._id)}
                                  >
                                    <span>{item?.title}</span>
                                  </Checkbox>
                                </li>
                              </>
                            ))}
                          </ul>
                        </div>
                        <div className="popover-footer-btn">
                          <Button
                            type="primary"
                            className="square-primary-btn"
                            onClick={() => {
                              getprojectexpencesList();
                              setPopOver({ ...popOver, project: false });
                            }}
                          >
                            Apply
                          </Button>
                          <Button
                            className="square-outline-btn"
                            onClick={() => {
                              setPopOver({ ...popOver, project: false });
                            }}
                          >
                            Cancel
                          </Button>
                        </div>
                      </div>
                    }
                  >
                    <i class="fa-solid fa-list-check"></i>{" "}
                    {selectedProject.length == 0 ? "All" : "Selected"}
                  </Popover>
                </div>}
              {getRoles(["Super Admin"]) &&
                <>

                  <div style={{ cursor: "pointer" }}>
                    <h6>Technology:</h6>
                    <Popover
                      trigger="click"
                      placement="bottomRight"
                      visible={popOver.technology}
                      onVisibleChange={() =>
                        handleVisibleChange("technology", true)
                      }
                      content={
                        <div className="assignees-popover assign-global-height">
                          <ul>
                            <li>
                              <Checkbox
                                checked={technology.length === 0}
                                onChange={() =>
                                  handleFilters("", technology, setTechnology)
                                }
                              >
                                {" "}
                                All
                              </Checkbox>
                            </li>
                          </ul>
                          <div className="search-filter">
                            <Input
                              placeholder="Search"
                              value={searchTechnology}
                              onChange={handleSearchTechnology}
                            />
                          </div>
                          <div>
                            <ul className="assigness-data">
                              {filteredTechnologyList.map((item, index) => (
                                <li key={item._id}>
                                  <Checkbox
                                    onChange={() => {
                                      handleFilters(
                                        item,
                                        technology,
                                        setTechnology
                                      );
                                    }}
                                    checked={technology.includes(item._id)}
                                  >
                                    {item.project_tech}
                                  </Checkbox>
                                </li>
                              ))}
                            </ul>
                          </div>
                          <div className="popover-footer-btn">
                            <Button
                              type="primary"
                              className="square-primary-btn"
                              onClick={() => {
                                getprojectexpencesList();
                                setPopOver({ ...popOver, technology: false });
                              }}
                            >
                              Apply
                            </Button>
                            <Button
                              className="square-outline-btn"
                              onClick={() => {
                                handleVisibleChange("technology", false);
                              }}
                            >
                              Cancel
                            </Button>
                          </div>
                        </div>
                      }
                    >
                      <i className="fas fa-briefcase"></i>{" "}
                      {technology.length == 0 ? "All" : "Selected"}
                    </Popover>
                  </div>
                  <div style={{ cursor: "pointer" }}>
                    <h6>Manager:</h6>
                    <Popover
                      trigger="click"
                      placement="bottomRight"
                      visible={popOver.manager}
                      onVisibleChange={() => handleVisibleChange("manager", true)}
                      content={
                        <div className="assignees-popover assign-global-height">
                          <ul>
                            <li>
                              <Checkbox
                                checked={manager.length === 0}
                                onChange={() =>
                                  handleFilters("", manager, setManager)
                                }
                              >
                                {" "}
                                All
                              </Checkbox>
                            </li>
                          </ul>
                          <div className="search-filter">
                            <Input
                              placeholder="Search"
                              value={searchManager}
                              onChange={handleSearchManager}
                            />
                          </div>
                          <div>
                            <ul className="assigness-data">
                              {filteredManagerList.map((item, index) => (
                                <li key={item._id}>
                                  <Checkbox
                                    onChange={() => {
                                      handleFilters(item, manager, setManager);
                                    }}
                                    checked={manager.includes(item._id)}
                                  >
                                    <MyAvatar
                                      userName={item?.manager_name || "-"}
                                      src={item?.emp_img}
                                      key={item?._id}
                                      alt={item?.manager_name}
                                    />
                                    <span>{removeTitle(item?.manager_name)}</span>
                                  </Checkbox>
                                </li>
                              ))}
                            </ul>
                          </div>
                          <div className="popover-footer-btn">
                            <Button
                              type="primary"
                              className="square-primary-btn"
                              onClick={() => {
                                getprojectexpencesList();
                                setPopOver({ ...popOver, manager: false });
                              }}
                            >
                              Apply
                            </Button>
                            <Button
                              className="square-outline-btn"
                              onClick={() => {
                                setPopOver({ ...popOver, manager: false });
                              }}
                            >
                              Cancel
                            </Button>
                          </div>
                        </div>
                      }
                    >
                      <i className="fi fi-rr-users"></i>{" "}
                      {manager.length == 0 ? "All" : "Selected"}
                    </Popover>
                  </div>

                  <div style={{ cursor: "pointer" }}>
                    <h6>Account Manager:</h6>
                    <Popover
                      trigger="click"
                      placement="bottomRight"
                      visible={popOver.accontManager}
                      onVisibleChange={() =>
                        handleVisibleChange("accontManager", true)
                      }
                      content={
                        <div className="assignees-popover assign-global-height">
                          <ul>
                            <li>
                              <Checkbox
                                checked={accontManager?.length === 0}
                                onChange={() =>
                                  handleFilters(
                                    "",
                                    accontManager,
                                    setAccountManager
                                  )
                                }
                              >
                                {" "}
                                All
                              </Checkbox>
                            </li>
                          </ul>
                          <div className="search-filter">
                            <Input
                              placeholder="Search"
                              value={searchAccountManager}
                              onChange={handleSearchAccountManager}
                            />
                          </div>
                          <div>
                            <ul className="assigness-data">
                              {filteredAccManagerList.map((item, index) => (
                                <li key={item._id}>
                                  <Checkbox
                                    onChange={() => {
                                      handleFilters(
                                        item,
                                        accontManager,
                                        setAccountManager
                                      );
                                    }}
                                    checked={accontManager?.includes(item._id)}
                                  >
                                    <MyAvatar
                                      userName={item?.manager_name || "-"}
                                      src={item?.emp_img}
                                      key={item?._id}
                                      alt={item?.manager_name}
                                    />
                                    <span>{removeTitle(item?.manager_name)}</span>
                                  </Checkbox>
                                </li>
                              ))}
                            </ul>
                          </div>
                          <div className="popover-footer-btn">
                            <Button
                              type="primary"
                              className="square-primary-btn"
                              onClick={() => {
                                getprojectexpencesList();
                                setPopOver({ ...popOver, accontManager: false });
                              }}
                            >
                              Apply
                            </Button>
                            <Button
                              className="square-outline-btn"
                              onClick={() => {
                                setPopOver({ ...popOver, accontManager: false });
                              }}
                            >
                              Cancel
                            </Button>
                          </div>
                        </div>
                      }
                    >
                      <i className="fi fi-rr-users"></i>{" "}
                      {accontManager?.length == 0 ? "All" : "Selected"}
                    </Popover>
                  </div>
                </>}

              <div style={{ cursor: "pointer" }}>
                <h6>Need to Bill Customer:</h6>
                <Popover
                  trigger="click"
                  placement="bottomRight"
                  visible={popOver.need_to_bill_customer}
                  onVisibleChange={() =>
                    handleVisibleChange("need_to_bill_customer", true)
                  }
                  content={
                    <div className="assignees-popover assign-global-height">
                      <ul>
                        <Radio.Group
                          onChange={handleReviewTypeFilter}
                          value={need_to_bill_customer}
                        >
                          <li>
                            <Radio value={"All"}> All</Radio>
                          </li>

                          <li>
                            <Radio value={"Yes"}>
                              {" "}
                              Yes
                            </Radio>
                          </li>
                          <li>
                            <Radio value={"No"}>
                              {" "}
                              No
                            </Radio>
                          </li>

                        </Radio.Group>
                      </ul>
                      <div className="popover-footer-btn">
                        <Button
                          type="primary"
                          className="square-primary-btn"
                          onClick={() => {
                            getprojectexpencesList();
                            setPopOver({ ...popOver, feedBackType: false });
                          }}
                        >
                          Apply
                        </Button>
                        <Button
                          className="square-outline-btn"
                          onClick={() => {
                            setPopOver({ ...popOver, feedBackType: false });
                          }}
                        >
                          Cancel
                        </Button>
                      </div>
                    </div>
                  }
                >
                  <i class="fa-solid fa-list-check"></i>{" "}
                  {need_to_bill_customer == "All" ? "All" : "Selected"}
                </Popover>
              </div>

              <Button
                className="mr2"
                id="exportButton"
                disabled={pagination.total != 0 ? false : true}
                onClick={exportCSV}
              >
                Export CSV
              </Button>

            </div>
          </div>
          <Table
            pagination={{
              showSizeChanger: true,
              pageSizeOptions: ["10", "20", "30"],
              showTotal: showTotal,
              ...pagination,
            }}
            columns={columns}
            onChange={handleTableChange}
            dataSource={projectexpencesList}
          />
        </Card>

      </div>
      <Modal
        width={"600px"}
        title={'Project Expense  Details'}
        destroyOnClose
        onCancel={() => { setIsModalOpenTopic(false); setFeedBackDetails([]) }}
        open={isModalOpenTopic}
        footer={null}
      //   style={{
      //     height: "100%",
      //     top: '0',
      //     display: 'flex',
      //     justifyContent: 'center',
      //     alignItems: 'center',
      //   }}
      >

        <Form form={formDetail} layout="vertical" style={{ padding: '20px' }}>
          <Form.Item label={<Text strong>Purchase Request Details</Text>} name="purchase_request_details">
            <Input.TextArea
              placeholder="Enter purchase request details"
              rows={4}
              disabled
              className="border-gray-300"
            />
          </Form.Item>
         {viewData?.details&&( <Form.Item label={<Text strong>Accounting Details</Text>} name="details">
            <Input.TextArea
              placeholder="Accounting details"
              rows={4}
              disabled
              className="border-gray-300"
            />
          </Form.Item>)}
          {/* Document Section */}
          {viewData?.projectexpences?.length > 0 && (
            <Row align="middle" style={{ marginTop: 16 }}>
              <Col>
                <Text strong>Document: </Text>
                <a
                  href={`${process.env.REACT_APP_API_URL}/public/projectexpense/${viewData?.projectexpences}`}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="ml-2 text-blue-500 hover:text-blue-700"
                >
                  <FileTextOutlined style={{ marginRight: 5 }} />
                  {viewData?.projectexpences}
                </a>
              </Col>
            </Row>
          )}
        </Form>
        {/* <div className="overview-modal-wrapper" dangerouslySetInnerHTML={{__html:feedBackDetails?.feedback}}> */}
        {/* {feedBackDetails?.feedback} */}
        {/* </div> */}
      </Modal>
    </>
  );
};

export default Projectexpences;
