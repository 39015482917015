import React, { useEffect, useLayoutEffect, useState } from "react";
import { Form, Menu, Popover, Layout } from "antd";
import { NavLink, useLocation } from "react-router-dom";
import CustomScrollbars from "../../util/CustomScrollbars";
import { THEME_TYPE_LITE } from "../../constants/ThemeSetting";
import IntlMessages from "../../util/IntlMessages";
import { useDispatch, useSelector } from "react-redux";
import { SearchOutlined } from "@ant-design/icons";
import { hideAuthLoader, showAuthLoader } from "../../appRedux/actions";
import Service from "../../service";
import { Link } from "react-router-dom/cjs/react-router-dom.min";
import "./SidebarContent.css";
import PropTypes from "prop-types";
import { getRoles } from "../../util/hasPermission";
import ProjectListModal from "../../components/Modal/ProjectListModal";
import { generateCacheKey } from "../../util/generateCacheKey";
import Taskhub  from "../../assets/images/taskhubicon.svg";

const { Sider } = Layout;


function SidebarContent({ setSidebarCollapsed, sidebarCollapsed }) {
  const { themeType } = useSelector(({ settings }) => settings);
  const { pathname } = useSelector(({ common }) => common);

  const location = useLocation();
  const dispatch = useDispatch();

  const selectedKeys = pathname.substr(1);
  const defaultOpenKeys = selectedKeys.split("/")[1];

  const [openKeys, setOpenKeys] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [projectDetails, setProjectDetails] = useState([]);
  const [isPopoverVisible, setIsPopoverVisible] = useState(false);
  const [isComplaintPopoverVisible, setIsComplaintPopoverVisible] = useState(false);
  const [recentList, setRecentList] = useState([]);
  const [form] = Form.useForm();
const [userData,setUserData]=useState(JSON.parse(localStorage.getItem("user_data")))
  const handleCancel = () => {
    setIsModalOpen(false);
    form.resetFields();
  };


  console.log(sidebarCollapsed, "sidebarCollapsed")

  // useEffect(() => {
  //   if (window.location.pathname == "/organization-view") {
  //     console.log('Riteh');

  //     setSidebarCollapsed(false);
  //     setOpenKeys([]);
  //   } else {
  //     setSidebarCollapsed(false);
  //   }
  // }, [window.location.pathname]);


  // open modal and calling getlisting api
  const showModal = async () => {
    setIsModalOpen(true);
    getProjectListing();
    getVisitedData();
  };

  const getProjectListing = async (searchText) => {
    try {
      dispatch(showAuthLoader());
      const defaultPayload = {
        pageNo: 1,
        limit: 5,
        search: searchText || "",
        // search: '',
        sortBy: "desc",
        filterBy: "all",
        isSearch: true,
      };
      const reqBody = {
        ...defaultPayload,
      };
      if (searchText && searchText !== "") {
        reqBody.search = searchText;
        // setSearchEnabled(true);
      }
      let Key = generateCacheKey("project", reqBody);

      const response = await Service.makeAPICall({
        methodName: Service.postMethod,
        api_url: Service.getProjectdetails,
        body: reqBody,
        options: {
          cachekey: Key,
        },
      });
      dispatch(hideAuthLoader());
      if (response?.data && response?.data?.data) {
        setProjectDetails(response?.data?.data);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const addVisitedData = async (projectId) => {
    try {
      dispatch(showAuthLoader());
      const response = await Service.makeAPICall({
        methodName: Service.postMethod,
        api_url: Service.addrecentVisited,
        body: {
          project_id: projectId,
        },
      });
      if (response?.data && response?.data?.statusCode == 200) {
        dispatch(hideAuthLoader());
      }
    } catch (error) {
      console.log("add project error");
    }
  };

  const getVisitedData = async () => {
    try {
      dispatch(showAuthLoader());
      const response = await Service.makeAPICall({
        methodName: Service.postMethod,
        api_url: Service.getrecentVisited,
      });
      if (response?.data && response?.data?.statusCode == 200) {
        dispatch(hideAuthLoader());
        setRecentList(response?.data?.data);
      }
    } catch (error) {
      console.log("get project error");
    }
  };

  // Use a single function to handle all popover visibility changes
  const handlePopoverVisibilityChange = (popoverType, isVisible) => {
    if (popoverType === 'reports') {
      setIsPopoverVisible(isVisible);
    } else if (popoverType === 'feedback') {
      console.log(isVisible, 'isVisible');

      setIsComplaintPopoverVisible(isVisible);
    }
  };
  const handleComplaintNavLinkClick = (e) => {
    e.preventDefault(); // Prevent default navigation behavior
    setIsComplaintPopoverVisible(true); // Show the Popover
  };

  // const handleNavLinkClick = (e) => {
  //   e.preventDefault(); // Prevent default navigation behavior
  //   // setIsPopoverVisible(true); // Show the Popover
  //   setIsComplaintPopoverVisible(true)
  // };
  const handleNavLinkClick = (e) => {
    e.preventDefault(); // Prevent default navigation behavior
    setIsPopoverVisible(true); // Show the Popover
    setIsComplaintPopoverVisible(true)
  };
  return (
    <>
      {/* <SidebarLogo
        sidebarCollapsed={sidebarCollapsed}
        setSidebarCollapsed={setSidebarCollapsed}
      /> */}
      <div className="gx-sidebar-content sidebar-menu">
        <div className="Etask-hub-logo">  <img alt="logo" src= { Taskhub }  /> </div> 
        <CustomScrollbars className="gx-layout-sider-scrollbar">

          <Sider collapsible collapsed={ sidebarCollapsed } onCollapse={ setSidebarCollapsed } className="Sidebar">

            <Menu
              defaultOpenKeys={ [defaultOpenKeys] }
              openKeys={ openKeys }
              selectedKeys={ [selectedKeys] }
              theme={ themeType === THEME_TYPE_LITE ? "lite" : "dark" }
              mode="inline"
            >
              <Menu.Item
                key="Search Project"
                title={ <span><IntlMessages id=" Search" /></span> }

                className={
                  location.pathname?.includes("/search-project-list")
                    ? "ant-menu-item-current"
                    : ""
                }
              >
                <NavLink
                  to="#"
                  onClick={ () => {
                    showModal();
                  } }
                >
                  <SearchOutlined />
                  <span>
                    Search
                  </span>
                </NavLink>
              </Menu.Item>
              { !getRoles(["Client"]) && (
                <Menu.Item
                  title={ <span><IntlMessages id="sidebar.dashboard" /></span> }
                  key="Dashboard"
                  className={
                    location.pathname?.includes("/dashboard")
                      ? "ant-menu-item-current"
                      : ""
                  }
                >
                  <NavLink to="/dashboard">
                    <i className="fi fi-rs-house-chimney"></i>
                    <span>
                      <IntlMessages id="sidebar.dashboard" />
                    </span>
                  </NavLink>
                </Menu.Item>
              ) }
              <Menu.Item
                title={ <span><IntlMessages id="sidebar.project-list" /></span> }

                key="Admin Dashboard"

                className={
                  location.pathname?.includes("/project-list") ||
                    location.pathname?.includes("/project/app")
                    ? "ant-menu-item-current"
                    : ""
                }
              >
                <NavLink to="/project-list">
                  <i className="fi fi-rr-dashboard"></i>
                  <span>
                    <IntlMessages id="sidebar.project-list" />
                  </span>
                </NavLink>
              </Menu.Item>

              { getRoles(["Super Admin"]) && (
                <Menu.Item
                  key="Users"
                  title={ <span><IntlMessages id="sidebar.project-users" /></span> }

                  className={
                    location.pathname?.includes("/project-users")
                      ? "ant-menu-item-current"
                      : ""
                  }
                >
                  <NavLink to="/project-users">
                    <i className="fi fi-rr-users-alt"></i>
                    <span>
                      <IntlMessages id="sidebar.project-users" />
                    </span>
                  </NavLink>
                </Menu.Item>
              ) }
              { getRoles(["Super Admin"]) && (
                <Menu.Item
                  title={ <span><IntlMessages id="sidebar.permission" /></span> }

                  key="Permission"
                  className={
                    location.pathname?.includes("/permission-access") ||
                      location.pathname?.includes("/roles-permission")
                      ? "ant-menu-item-current"
                      : ""
                  }
                >
                  <NavLink to="/permission-access">
                    <i className="fi fi-rr-lock"></i>
                    <span>
                      <IntlMessages id="sidebar.permission" />
                    </span>
                  </NavLink>
                </Menu.Item>
              ) }

              { (getRoles(["Super Admin", "Admin"])||
              userData._id=="660a38c0768eaa003f5727c8")  && (
                <Menu.Item

                  key="Reports"
                  title={ null }
                  className={
                    location.pathname?.includes("/timesheet-reports") ||
                      location.pathname?.includes("/project-runnig-reports")
                      ? "ant-menu-item-current"
                      : ""
                  }
                >
                  <Popover
                    placement="left"
                    trigger="hover"
                    className={
                      location.pathname?.includes("/reports") ||
                        location.pathname?.includes("/timesheet-reports") ||
                        location.pathname?.includes("/project-runnig-reports")
                        ? "ant-menu-item-current"
                        : ""
                    }
                    content={
                      <div className="right-popover-wrapper">
                        <Link to="/project-runnig-reports">
                          <p>Projects- running</p>
                        </Link>
                        <Link to="/timesheet-reports">
                          <p>Timesheet</p>
                        </Link>
                      </div>
                    }
                  >
                    <NavLink to="/reports" onClick={ handleNavLinkClick }>
                      <i className="fi fi-rs-newspaper"></i>
                      <span>
                        <IntlMessages id="sidebar.reports" />
                      </span>
                    </NavLink>
                    {/* {/* <NavLink > */ }
                    {/* <i className="fi fi-rs-newspaper"></i>
                    <span>
                      <IntlMessages id="sidebar.reports" />
                    </span> */}
                    {/* </NavLink> */ }
                  </Popover>
                </Menu.Item>

              ) }


              { getRoles(["Super Admin", "PC", "TL", "Admin", "AM", "User"]) && (
                <Menu.Item
                  title={ <span><IntlMessages id="sidebar.hours" /></span> }

                  key="Hours"
                  className={
                    location.pathname?.includes("/billable-hours") ||
                      location.pathname?.includes("/billable-hours-pc")
                      ? "ant-menu-item-current"
                      : ""
                  }
                >
                  { getRoles(["Super Admin", "PC", "TL", "Admin", "AM", "User"]) && (
                    <NavLink to="/billable-hours">
                      <i className="fa-solid fa-clock"></i>
                      <span>
                        <IntlMessages id="sidebar.hours" />
                      </span>
                    </NavLink>
                  ) }
                </Menu.Item>
              ) }
              { getRoles(["Super Admin", "PC", "TL", "Admin", "AM"]) && (
                <Menu.Item
                  key="FeedBack"
                  title={ null }
                  className={
                    location.pathname?.includes("/complaints") ||
                      location.pathname?.includes("/positive-review")
                      ? "ant-menu-item-current"
                      : ""
                  }
                >
                  <Popover

                    placement="left"
                    trigger="hover"
                    className={
                      location.pathname?.includes("/feedback") ||
                        location.pathname?.includes("/positive-review") ||
                        location.pathname?.includes("/complaints")
                        ? "ant-menu-item-current"
                        : ""
                    }
                    content={
                      <div className="right-popover-wrapper">
                        <Link to="/positive-review">
                          <p>Positive Reviews</p>
                        </Link>
                        <Link to="/complaints">
                          <p>Complaints</p>
                        </Link>
                      </div>
                    }
                  >
                    <NavLink to="/feedback" onClick={ handleNavLinkClick }>
                      <i className="fa-solid fa-comments"></i>
                      <span>
                        <IntlMessages id="sidebar.feedBack" />
                      </span>
                    </NavLink>
                  </Popover>
                </Menu.Item>

              ) }
               {/* // projectExpe. */}
              {(getRoles(["Super Admin", "PC", "TL", "Admin"]) || userData._id == '67c81990506e8203272e6267') && (
                


                <Menu.Item
                title={ <span><IntlMessages id="sidebar.projectexpences" /></span> }

                  key="Projectexpences"
                // className={
                //   location.pathname?.includes("/permission-access") ||
                //     location.pathname?.includes("/roles-permission")
                //     ? "ant-menu-item-current"
                //     : ""
                // }
                >
                  <NavLink to="/projectexpense">
                    <i className="fi fi-rr-receipt"></i>
                    <span>
                      <IntlMessages id="sidebar.projectexpences" />
                    </span>
                  </NavLink>
                </Menu.Item>
              )}

            </Menu>
          </Sider>
          <ProjectListModal
            projectDetails={ projectDetails }
            recentList={ recentList }
            isModalOpen={ isModalOpen }
            handleCancel={ handleCancel }
            addVisitedData={ addVisitedData }
            setIsModalOpen={ setIsModalOpen }
            form={ form }
            getProjectListing={ getProjectListing }
          />
        </CustomScrollbars>
      </div>
    </>
  );
}

SidebarContent.propTypes = {
  setSidebarCollapsed: PropTypes.func.isRequired,
};
export default SidebarContent;