import React, { useEffect, useState } from "react";
import { Button, Checkbox, Col, Form, Input, message, Modal, Radio, Row, Select, Typography, Upload } from "antd";
import { Header } from "antd/es/layout/layout";
import Service from "../../service";
import { hideAuthLoader, showAuthLoader } from "../../appRedux/actions";
import { useDispatch } from "react-redux";
import TextArea from "antd/es/input/TextArea";
import "../Complaints/ComplaintsForm.css";
import "../../assets/css/pms.css";
import "../../assets/css/style.css";
import { useHistory, useParams } from "react-router-dom"
import { CloseCircleOutlined, DeleteOutlined, DollarCircleOutlined, FileTextOutlined, UploadOutlined } from "@ant-design/icons";

const ProjectexpencesForm = () => {

  const { review_id } = useParams();
  const [form] = Form.useForm();
  const { Option } = Select;
  const formItemLayout = {
    labelCol: {
      xs: { span: 24 },
      sm: { span: 8 },
    },
    wrapperCol: {
      xs: { span: 24 },
      sm: { span: 16 },
    },
  };
  const dispatch = useDispatch();
  const history = useHistory();
  // const navigate= useNavigate()
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [invoiceFile, setInvoiceFile] = useState(null);
  const { Text } = Typography;

  const [projects, setProjects] = useState([]);
  const [file, setFile] = useState([]); // Store selected file
  const [selectedFile, setSelectedFile] = useState(null);
  const [viewData, setViewData] = useState({})

  const [isRecuring, setIsRecuring] = useState(false);
  const [billingCycle, setBillingCycle] = useState("monthly"); // Default: Monthly

  const [selectedFeedbackType, setSelectedFeedbackType] = useState("");
  const [projectDetails, setProjectDetails] = useState();
  const [reviewId, setReviewId] = useState();
  const [userRole, setuserRole] = useState(JSON.parse(localStorage.getItem('user_data')))
  const [staticAccountantId, setstaticAccountantId] = useState(['67c81990506e8203272e6267'])
  const handleFeedbackTypeChange = (value) => {
    setSelectedFeedbackType(value);
  };

  useEffect(() => {
    getProjects();
    if (review_id != undefined) {
      getReviewForEdit(review_id);
    }
  }, []);

  const getProjects = async () => {
    try {
      dispatch(showAuthLoader());

      const response = await Service.makeAPICall({
        methodName: Service.postMethod,
        api_url: Service.myProjects,
      });
      dispatch(hideAuthLoader());
      if (response?.data && response?.data?.data) {
        setProjects(response.data.data);
      }
    } catch (error) {
      console.log(error);
    }
  };


  const getProjectDetails = async (project_id) => {
    try {
      dispatch(showAuthLoader());
      const params = `/${project_id}`;
      const response = await Service.makeAPICall({
        methodName: Service.getMethod,
        api_url: Service.getOverview + params,
      });
      dispatch(hideAuthLoader());
      if (response?.data && response?.data?.data) {
        setProjectDetails(response.data.data);
        form.setFieldsValue({
          project_manager: response.data.data?.manager?.full_name,
          account_manager: response.data.data?.acc_manager?.full_name,
        });
      }
    } catch (error) {
      console.log(error);
    }
  };


  const getReviewForEdit = async (review_id) => {
    setReviewId(review_id);
    try {
      dispatch(showAuthLoader());
      const reqBody = {
        _id: review_id,
      };
      const response = await Service.makeAPICall({
        methodName: Service.postMethod,
        api_url: Service.getprojectexpanses,
        body: reqBody,
      });

      dispatch(hideAuthLoader());
      if (response?.data && response?.data?.data) {
        setSelectedFeedbackType(response.data.data?.feedback_type)
        setViewData(response.data.data)
        // console.log(response.data.data,'response.data.data');


        if (response.data.data?.project?._id) {
          await getProjectDetails(response.data.data?.project?._id)
        }
        {
          staticAccountantId.includes(userRole?._id) && !(
            userRole?.pms_role_id?.role_name === "Super Admin"
          ) ? <>
            {form.setFieldsValue({
              project: response.data.data?.project?.title,
              project_manager: response.data.data?.manager?.full_name,
              account_manager: response.data.data?.acc_manager?.full_name,
              purchase_request_details: response.data.data?.purchase_request_details.replace(/<br\s*\/?>/g, "\n"),
              cost_in_usd: response.data.data?.cost_in_usd,
              need_to_bill_customer: response.data.data?.need_to_bill_customer,
              status: response.data.data?.status,
              details: response.data.data?.details,
              billing_cycle:response.data.data?.billing_cycle,
          is_recuring:response.data.data?.is_recuring
            })}</> : <>{form.setFieldsValue({
              project: response.data.data?.project?._id,
              project_manager: response.data.data?.manager?.full_name,
              account_manager: response.data.data?.acc_manager?.full_name,
              purchase_request_details: response.data.data?.purchase_request_details.replace(/<br\s*\/?>/g, "\n"),
              cost_in_usd: response.data.data?.cost_in_usd,
              need_to_bill_customer: response.data.data?.need_to_bill_customer,
              status: response.data.data?.status,
              details: response.data.data?.details,
              billing_cycle:response.data.data?.billing_cycle,
              is_recuring:response.data.data?.is_recuring
            })}</>
            
          // project: response.data.data?.project?.full_name},
          setIsRecuring(response.data.data?.is_recuring)


        }
      }
    } catch (error) {
      console.log(error);
    }
  };


  // Handle file upload
  const handleFileChange = (info) => {
    const uploadedFile = info.fileList; // Get the array of files
    console.log(uploadedFile, "uploadedFile");

    setFile(uploadedFile);
    // Save file list to state
    setSelectedFile(uploadedFile)
  };
  const handleRemoveFile = () => {
    setSelectedFile(null);
  };
  // Submit FormData
  const handleSubmit = async (values) => {
    try {
      const formData = new FormData();

      // Ensure file is selected and properly appended
      if (file.length > 0) {
        file.forEach((item) => {
          if (item.originFileObj) {
            formData.append("projectexpences", item.originFileObj);
          }
        });
      }

      // Append form fields
      formData.append("cost_in_usd", values.cost_in_usd);
      formData.append("project_id", values.project);
      formData.append("purchase_request_details", values.purchase_request_details);
      formData.append("need_to_bill_customer", values.need_to_bill_customer);
      formData.append("status", values.status);
      if (values.details && values.details.trim() !== "") {
        formData.append("details", values.details);
      }
      if (values.billing_cycle && values.is_recuring==true) {
        formData.append("billing_cycle", values.billing_cycle);
        formData.append("is_recuring", values.is_recuring);
      }
      console.log("Submitting form with payload:", Object.fromEntries(formData.entries()));

      // Check if updating or creating a new record
      if (reviewId) {
        const params = `/${reviewId}`;
        const response = await Service.makeAPICall({
          methodName: Service.putMethod,
          api_url: Service.updateprojectexpanses + params,
          body: formData,
          headers: { "Content-Type": "multipart/form-data" }, // Ensure correct headers
        });

        if (response.data && response.data.data) {
          message.success(response.data.message);
          history.push("/projectexpense");
        } else {
          message.error(response.data.message);
        }
      } else {
        let reviewData = {
          cost_in_usd: values.cost_in_usd,
          project_id: values.project,
          purchase_request_details: values.purchase_request_details,
          need_to_bill_customer: values.need_to_bill_customer,
          status: values.status,
       
         
          // formData.append("billing_cycle", values.billing_cycle);
          // formData.append("is_recuring", values.is_recuring);

        }
        if (values.billing_cycle && values.is_recuring) {
        
          reviewData={...reviewData,  billing_cycle:values.billing_cycle,
          is_recuring:values.is_recuring}
        }
        const response = await Service.makeAPICall({
          methodName: Service.postMethod,
          api_url: Service.addprojectexpanses,
          body: reviewData,
          headers: { "Content-Type": "multipart/form-data" }, // Ensure correct headers
        });

        if (response && response.data && response.data.data && response.data.statusCode === 201) {
          console.log(response.data.data, "responseee");
          message.success(response.data.message);
          history.push("/projectexpense");
        } else {
          message.error(response.data.message);
        }
      }
    } catch (error) {
      console.error("Error submitting form:", error);
    }
  };

  // Function to handle status change


  const handleStatusChange = (value) => {
    if (value === "Paid") {
      setIsModalOpen(true);
    } else {
      setIsModalOpen(false);
    }
  };

  // Function to handle file upload
  const handleFileUpload = (file) => {
    setInvoiceFile(file);
  };

  // Function to handle modal submission
  const handleModalSubmit = () => {
    setIsModalOpen(false);
  };
  console.log(selectedFile, 'selectedFile', viewData)

  return (
    <>
      <div className="main-time-sheet-project-wrapper" style={{ overflow: 'auto' }}>
        <Header className="main-header">
          <div className="project-name">
            <h3 style={{ textTransform: "capitalize" }}>
              {reviewId ? "Edit" : "Add"} Project Expense </h3>
          </div>
        </Header>
        <div className="project-wrapper new-project-overview project-running-reports">
          <div className="peoject-page">
            <div className="header">
              <div className="project-running-reports-fillter-wrapper project-expences-wrapper feedback-form">
                <Form
                  form={form}
                  noValidate
                  // noValidate={ true }
                  {...formItemLayout}
                  onFinish={handleSubmit}
                >
                  <Row>
                    <Col span={12}>
                      {staticAccountantId.includes(userRole?._id) && !(
                        userRole?.pms_role_id?.role_name === "Super Admin"
                      ) ? <> <Form.Item
                        name="project"
                        label="ProjectT"
                        rules={[
                          {
                            required: true,
                            message: "Please Select Project !",
                          },
                        ]}
                      >
                        <Input
                          placeholder="Project"
                          disabled
                        />
                      </Form.Item></> : <> <Form.Item
                        name="project"
                        label="Project"
                        rules={[
                          {
                            required: true,
                            message: "Please Select Project !",
                          },
                        ]}
                      >
                        <Select
                          // style={ { width: 200 } }
                          placeholder="Project"
                          showSearch
                          filterOption={(input, option) =>
                            option.children
                              ?.toLowerCase()
                              .indexOf(input?.toLowerCase()) >= 0
                          }
                          filterSort={(optionA, optionB) =>
                            optionA.children
                              ?.toLowerCase()
                              .localeCompare(optionB.children?.toLowerCase())
                          }
                          // value={value}
                          onChange={(e) => getProjectDetails(e)}
                        >
                          {projects.map((item, index) => (
                            <Option
                              key={index}
                              value={item._id}
                              style={{ textTransform: "capitalize" }}
                            >
                              {item?.title}
                            </Option>
                          ))}
                        </Select>
                      </Form.Item></>}


                    </Col>
                    <Col span={12}>
                      <Form.Item
                        name="project_manager"
                        label="Project Manager"
                        rules={[
                          {
                            required: true,
                            message: "Please enter Project Manager name !",
                          },
                        ]}
                      >
                        <Input
                          placeholder="Enter Project Manager name"
                          disabled
                        />
                      </Form.Item>
                    </Col>
                  </Row>
                  <Row>
                    <Col span={12}>
                      <Form.Item
                        name="account_manager"
                        label="Account Manager"
                        rules={[
                          {
                            required: true,
                            message: "Please Select Account Manager !",
                          },
                        ]}
                      >
                        <Input
                          placeholder="Enter Account Manager name"
                          disabled
                        />
                      </Form.Item>
                    </Col>
                    <Col span={12}>
                      <Form.Item

                        label="Cost in USD"
                        name="cost_in_usd"
                        rules={[
                          {
                            required: true,
                            message: "Please enter the cost in USD!",
                          },
                          {
                            pattern: /^\d+(\.\d{1,2})?$/,
                            message: "Please enter a valid cost (up to 2 decimal places)!",
                          },
                        ]}
                      >
                        <Input addonBefore={<DollarCircleOutlined className="text-gray-500" />}
                          placeholder="Enter cost in USD" type="number" min="0" step="0.01" />
                      </Form.Item>
                    </Col>
                  </Row>

                  <Row>

                    {/* <Col span={12} style={{textAlign: "center"}}>
                      <Form.Item
                        label="Need to Bill Customer?"
                        name="need_to_bill_customer"
                        initialValue={true} // Default value is "Yes"
                      >
                        <Radio.Group>
                          <Radio value={true}>Yes</Radio>
                          <Radio value={false}>No</Radio>
                        </Radio.Group>
                      </Form.Item>
                    </Col> */}


                    <Col span={12}>
                      <Form.Item
                        label="Need to Bill Customer?"
                        name="need_to_bill_customer"
                        initialValue={true} // Default value is "Yes"
                      >
                        <Radio.Group>
                          <Radio value={true}>Yes</Radio>
                          <Radio value={false}>No</Radio>
                        </Radio.Group>
                      </Form.Item>
                    </Col>


                    <Col span={12}>
                      <Form.Item
                        label="Is Recuring Purchase?"
                        name="is_recuring"
                        initialValue={isRecuring} 
                        onChange={(e) => setIsRecuring(e.target.value)}

                      >
                        <Radio.Group>
                          <Radio value={true}>Yes</Radio>
                          <Radio value={false}>No</Radio>
                        </Radio.Group>
                      </Form.Item>
                    </Col>


                  </Row>
                  <Row>
                    <Col span={12}>
                      <Form.Item
                        label="Purchase Request Details"
                        name="purchase_request_details"
                        rules={[
                          {
                            required: true,
                            message: "Please enter purchase request details!",
                          },
                        ]}
                      >
                        <Input.TextArea placeholder="Enter purchase request details" rows={4} />
                      </Form.Item>
                    </Col>
                   
                    {isRecuring ? ( // Show only when "Yes" is selected
                      <Col span={12}>
                        <Form.Item label="Billing Cycle" name="billing_cycle" initialValue={billingCycle}>
                          <Select
                            value={billingCycle}
                            onChange={(value) => setBillingCycle(value)}
                            style={{ width: "100%" }}
                            placeholder="Select Billing Cycle"
                            disabled={!isRecuring}
                          >
                            <Option value="monthly">Monthly</Option>
                            <Option value="yearly">Yearly</Option>
                          </Select>
                        </Form.Item>
                      </Col>
                    ):null}


                  </Row>
               
                  {reviewId && (<><hr></hr></>)}
                  {reviewId && (

                    <>
                      <Row style={{ paddingTop: "16px" }} >

                        <Col span={12} style={{ paddingTop: "16px" }} >
                          {(userRole?.pms_role_id?.role_name === "Super Admin" ||
                            staticAccountantId.includes(userRole?._id) ||
                            userRole?.pms_role_id?.role_name === "Admin") && (
                              <Form.Item name="status" label="Status">
                                <Select
                                  placeholder="Select Status"
                                  onChange={handleStatusChange}
                                  disabled={!(
                                    userRole?.pms_role_id?.role_name === "Super Admin" ||
                                    staticAccountantId.includes(userRole?._id) ||
                                    userRole?.pms_role_id?.role_name === "Admin"
                                  )}
                                >
                                  {staticAccountantId.includes(userRole?._id) ? (
  // If user is in staticAccountantId and is a Super Admin, show only "Paid"
  userRole?.pms_role_id?.role_name === "Super Admin" && <Option value="Paid">Paid</Option>
) : (
  <>
    {/* If user is a Super Admin (but NOT in staticAccountantId), show all options */}
    {userRole?.pms_role_id?.role_name === "Super Admin" && (
      <>
        <Option value="Approved">Approved</Option>
        <Option value="Rejected">Rejected</Option>
        <Option value="Paid">Paid</Option>
      </>
    )}

    {/* If user is an Admin, show only Approved & Rejected */}
    {userRole?.pms_role_id?.role_name === "Admin" && (
      <>
        <Option value="Approved">Approved</Option>
        <Option value="Rejected">Rejected</Option>
      </>
    )}
  </>
)}

                                  {/* {(userRole?.pms_role_id?.role_name === "Super Admin") && (
                                    <>
                                      <Option value="Approved">Approved</Option>
                                      <Option value="Rejected">Rejected</Option>
                                      <Option value="Paid">Paid</Option>
                                    </>
                                  )}
                                  {userRole?.pms_role_id?.role_name === "Admin" && (
                                    <>
                                      <Option value="Approved">Approved</Option>
                                      <Option value="Rejected">Rejected</Option>
                                    </>
                                  )}
                                  {staticAccountantId.includes(userRole?._id) && !(
                                    userRole?.pms_role_id?.role_name === "Super Admin"
                                  ) && <Option value="Paid">Paid</Option>} */}
                                </Select>
                              </Form.Item>

                            )}
                        </Col>
                        <Col span={12}>
                          {isModalOpen ?
                            <Row >
                              <Col span={24}>
                                <Form.Item
                                  label="Accounting Details"
                                  name="details"
                                  rules={[
                                    {
                                      required: true,
                                      message: "Please enter  details!",
                                    },
                                  ]}
                                >
                                  <Input.TextArea placeholder="Enter  details" rows={4} />
                                </Form.Item>
                              </Col>                            {/* Upload Invoice */}
                              <Col xs={24} sm={24}>
                                <Form.Item
                                  label={<Text className="font-medium">Upload Invoice</Text>}
                                  name="invoice"
                                  valuePropName="file"
                                  rules={[{ required: true, message: "Please upload an invoice!" }]}
                                  labelCol={{ span: 8 }}  // Adjust label width
                                  wrapperCol={{ span: 16 }} // Adjust field width
                                >
                                  <Upload maxCount={1} beforeUpload={() => false} showUploadList={false} onChange={handleFileChange}>
                                    <Button icon={<UploadOutlined />} className="border-blue-500 text-blue-500 hover:border-blue-700 hover:text-blue-700">
                                      Click to Upload
                                    </Button>
                                  </Upload>
                                </Form.Item>

                                {selectedFile && (
                                  <Row align="middle" className="project-expenses-hover-effect" style={{ marginTop: -6, paddingLeft: 10, marginBottom: 20, width: 350, justifyContent: "space-between", padding: 5 }}>
                                    <Text>
                                      {selectedFile?.map((cvl) =>
                                        cvl?.name?.length > 30 ? cvl.name.slice(0, 30) + "..." : cvl.name
                                      )}
                                    </Text>

                                    <DeleteOutlined
                                      style={{ color: "#545454", marginLeft: 10, cursor: "pointer" }}
                                      onClick={handleRemoveFile}
                                    />
                                  </Row>
                                )}

                              </Col>



                            </Row> : ''}
                          {viewData?.projectexpences?.length > 0 && (

                            <Row align="middle" style={{ marginTop: 16 }}>
                              <Col span={24}>
                                <Form.Item
                                  label="Accounting Details"
                                  name="details"
                                  rules={[
                                    {
                                      required: true,
                                      message: "Please enter  details!",
                                    },
                                  ]}
                                >
                                  <Input.TextArea placeholder="Enter  details" rows={4} />
                                </Form.Item>
                              </Col>
                              <Col style={{ padding: "20px 0  " }}>
                                <Text strong style={{ paddingRight: "5px  " }}> Document: </Text>
                                <a
                                  href={`${process.env.REACT_APP_API_URL}/public/projectexpense/${viewData?.projectexpences}`}
                                  target="_blank"
                                  rel="noopener noreferrer"
                                  className="ml-2 text-blue-500 hover:text-blue-700"
                                >
                                  <FileTextOutlined style={{ marginRight: 5 }} />
                                  {viewData?.projectexpences[0]?.length > 30
                                    ? viewData?.projectexpences[0].slice(0, 30) + "..."
                                    : viewData?.projectexpences[0]}
                                </a>
                              </Col>
                            </Row>
                          )}

                        </Col>
                      </Row></>
                  )}



                  <Form.Item>
                    <div className="feedback-submit-button-form" style={{ paddingTop: "15px" }}>
                      <Button id="addbutton" type="primary" htmlType="submit">
                        {reviewId ? "Update" : "Submit"}
                      </Button>
                      <Button type="primary" onClick={() => history.push("/projectexpense")}>
                        Cancel
                      </Button>
                    </div>
                  </Form.Item>
                </Form>
              </div>



            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ProjectexpencesForm;
